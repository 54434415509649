import DefaultLayout from '@website/components/layouts/DefaultLayout';
import StaticPageContent, {
  ContentFulPage,
} from '@website/components/StaticPageContent/StaticPageContent';
import gql from 'graphql-tag';
import { GetStaticPathsResult } from 'next';
import { PropsWithChildren } from 'react';
import { contentFulClient } from './blog/[postSlug]';

export const staticPagesQuery = gql`
  query {
    pagesCollection {
      items {
        slug
      }
    }
  }
`;

export const aboutUsPageQuery = gql`
  query ($pageSlug: String!) {
    pagesCollection(limit: 1, where: { slug: $pageSlug }) {
      items {
        slug
        title
        image {
          url
          title
        }
        description
        keywords
        content {
          json
          links {
            assets {
              block {
                url
                sys {
                  id
                }
                title
                description
              }
            }
          }
        }
        metaTitle
        description
        keywords
      }
    }
  }
`;

type StaticPagesResponse = {
  pagesCollection: {
    items: {
      slug: string;
    }[];
  };
};

type StaticPagesParams = {
  page: string;
};

export async function getStaticPaths(): Promise<
  GetStaticPathsResult<StaticPagesParams>
> {
  const { data } = await contentFulClient.query({
    query: staticPagesQuery,
  });

  const {
    pagesCollection: { items },
  } = data as StaticPagesResponse;

  const paths = items
    // Exclude the about page, as it's handled separately
    .filter(({ slug }) => slug !== 'about')
    .map(({ slug }) => ({
      params: {
        page: slug,
      },
    }));

  return {
    paths,
    fallback: true,
  };
}

export async function getStaticProps({ params: { page } }) {
  const res = await contentFulClient.query({
    query: aboutUsPageQuery,
    variables: {
      pageSlug: page,
    },
    fetchPolicy: 'no-cache',
  });

  // If the page doesn't exist, return 404
  if (!res.data.pagesCollection?.items?.[0]) {
    return {
      notFound: true,
    };
  }

  if (res.errors) {
    console.error(res.errors);
    throw new Error('Failed to fetch API');
  }

  const pageContent = (await res.data.pagesCollection
    ?.items?.[0]) as ContentFulPage;

  return {
    props: {
      page: pageContent,
    },
    revalidate: 2,
  };
}

type StaticPagesProps = {
  page: ContentFulPage;
};

export default function StaticPages({ page }: StaticPagesProps) {
  return (
    <>
      <StaticPageContent page={page} />
    </>
  );
}

StaticPages.Layout = function Layout({ children }: PropsWithChildren<{}>) {
  return <DefaultLayout className="">{children}</DefaultLayout>;
};
